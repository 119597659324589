import {computed, inject, Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '@auth/data-access';
import {LanguageService} from '@core/language';
import {filter, map, mergeMap} from 'rxjs';
import {GlobalAppRouteConfig} from '@global-data';
import {environment} from '@shared-types';
import {DeviceType} from 'ngx-device-detector';

@Injectable({
	providedIn: 'root',
})
export class SidenavService {
	/* ------------------------------------------ PROVIDERS / SERVICES ------------------------------------------ */
	protected readonly authService: AuthService = inject(AuthService);
	private readonly languageService: LanguageService = inject(LanguageService);
	/* ------------------------------------------------  Signals ------------------------------------------------ */
	public readonly isSidenavAvailable: Signal<boolean> = computed(() => this.authService.isAuthenticated() && this.authService.ActiveUser.isPermanent());
	public readonly sideNavPosition: Signal<'right' | 'left'> = computed(() => {
		const langData = this.languageService.currentLangData();
		return langData?.dir === 'rtl' ? 'right' : 'left';
	});

	public readonly sideNavToggleBtnLabel: Signal<string> = computed(() => {
		return `dccomp.sidenav.modes.${this.isSidenavOpen() ? 'hide' : 'expand'}`;
	});

	public readonly sideNavToggleBtnIcon: Signal<string> = computed(() => {
		const langData = this.languageService.currentLangData();
		const isSidenavOpen = this.isSidenavOpen();
		const fromOrTo = isSidenavOpen ? 'from' : 'to';
		const iconName = `fa-arrow-${isSidenavOpen ? langData.position : langData.oppositeLang.position}-${fromOrTo}-arc`;
		return `fa-duotone ${iconName} fa-xl`;
	});

	/* -------------------------------------------------- Data -------------------------------------------------- */
	public readonly isSidenavOpen: WritableSignal<boolean> = signal(environment.deviceType !== DeviceType.Mobile);

	/* ------------------------------------------------  Constructor ------------------------------------------------ */
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		this.startRouterListener();
	}

	/* ------------------------------------------------  Methods ------------------------------------------------ */
	/** @description - Open the sidenav */
	public open(): void {
		this.isSidenavOpen.set(true);
	}

	/** @description - Close the sidenav */
	public close(): void {
		this.isSidenavOpen.set(false);
	}

	/** @description - Toggle the sidenav */
	public toggle(): void {
		this.isSidenavOpen.set(!this.isSidenavOpen());
	}

	/**
	 * @description - Listen to router events and close the sidenav if the route has autoHideSidenav data
	 * @returns void
	 */
	private startRouterListener(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map(route => {
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter(route => route.outlet === 'primary'),
				mergeMap(route => route.data),
			)
			.subscribe((data: GlobalAppRouteConfig) => {
				if (data?.autoHideSidenav || environment.deviceType === DeviceType.Mobile) {
					this.close();
				} else {
					this.open();
				}
			});
	}
}
