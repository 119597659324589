/**
 *  - Company Logo (or Company Name)
 *  - Main Content:
 *     - Top Section:
 *         1. Home (link)
 *         2. New (menu button)
 *         3. Documents (category)
 *         4. Templates (category)
 *         5. Forms (category)
 *      - Bottom Section:
 *         1. Company Settings (link)
 *         2. Users (link) - for superusers
 *         3. Address Book (link)
 *         4. Swagger (link) - for developers
 *  - Footer:
 *     - Top: DocCycle Application Info - Logo,  Version Change Log - Icon Button(open modal), Version Number (tooltip)
 *     - Bottom: User Area - User Display Name, Avatar Icon Button (open overlay with options)
 *
 *
 *  Note:
 *     1. foreach item (not category) has: link, icon, label, badge
 *     2. category has: label, items
 */
import {MenuItem, TreeNode} from 'primeng/api';

import {Router} from '@angular/router';
import {signal, WritableSignal} from '@angular/core';
import {PermissionType} from '@core/api/data-access'
import {MainInjector, uuidv4} from '@shared-types';

export enum SideNavSectionType {
	Top = 'top',
	Bottom = 'bottom',
}

export const SideNavbarItemType = {
	Category: 'category', // category of items
	Link: 'link', // link to a route
	Button: 'button', // button with callback
	Separator: 'separator', // separator
} as const;
export type SideNavbarItemType = (typeof SideNavbarItemType)[keyof typeof SideNavbarItemType];

export abstract class SideNavbarItem<K = string> {
	key: K;
	abstract readonly type: SideNavbarItemType;
	abstract label: string;
	abstract availableOnMobile: boolean;
	icon?: string;
	badge: WritableSignal<number> = signal(NaN);
	treeNode: TreeNode;
	nodeElement: HTMLElement;

	abstract permissions: {
		all?: PermissionType[];
		none?: PermissionType[];
	};

	asCategory?(): SideNavbarCategory;

	asLink?(): SideNavbarLink<K>;

	asButton?(): SideNavbarButton;

	protected constructor() {
		this.key = uuidv4() as K;
	}

	/* -------------------------------------------------- */
	get isCategory(): boolean {
		return this.type === SideNavbarItemType.Category;
	}

	get isLink(): boolean {
		return this.type === SideNavbarItemType.Link;
	}

	get isButton(): boolean {
		return this.type === SideNavbarItemType.Button;
	}

	get isSeparator(): boolean {
		return this.type === SideNavbarItemType.Separator;
	}

	/* -------------------------------------------------- */
	public resetNodeRecursively(): void {
		function resetNode(node: TreeNode) {
			node.expanded = false;
			node.styleClass = node.styleClass?.trim().replace('is-selected', '');
		}

		const node = this.treeNode;
		if (node) {
			setTimeout(() => {
				resetNode(node);
				if (node.children?.length) {
					node.children.forEach(child => resetNode(child));
				}
				if (node.parent) {
					resetNode(node.parent);
				}
			}, 0);
		}
	}
	/* -------------------------------------------------------------------------------------------------------------- */
	menuItem: MenuItem;
}

export class SideNavbarCategory extends SideNavbarItem {
	readonly type: SideNavbarItemType = SideNavbarItemType.Category;
	label: string;

	availableOnMobile: boolean = true;

	permissions: {
		all?: PermissionType[];
		none?: PermissionType[];
	} = {
		all: [],
		none: [],
	};

	items: SideNavbarItem[] = [];

	override asCategory(): SideNavbarCategory {
		return this;
	}

	constructor(init: Partial<SideNavbarCategory>) {
		super();
		Object.assign(this, init);
	}
}

/* -------------------------------------------------- Link -------------------------------------------------- */
export class SideNavbarLink<K> extends SideNavbarItem<K> {
	readonly type: SideNavbarItemType = SideNavbarItemType.Link;
	label: string;
	route: string;

	availableOnMobile: boolean = true;

	permissions: {
		all?: PermissionType[];
		none?: PermissionType[];
	} = {
		all: [],
		none: [],
	};

	closeParentAfterNavigation: boolean = false;

	contextMenuItemsFn?: (item: SideNavbarLink<any>) => MenuItem[] = undefined;
	contextMenuItems: MenuItem[] = [];

	override asLink(): SideNavbarLink<K> {
		return this;
	}

	constructor(init: Partial<SideNavbarLink<K>>, key: K = uuidv4() as K) {
		super();
		Object.assign(this, init);
		this.key = key;
		if (init.contextMenuItemsFn) {
			this.contextMenuItems = init.contextMenuItemsFn(this);
		}
	}

	navigate() {
		if (this.closeParentAfterNavigation) {
			this.resetNodeRecursively();
		}
		const router = MainInjector.get(Router);
		return router.navigate([this.route]);
	}
}

/* -------------------------------------------------- Button -------------------------------------------------- */
export class SideNavbarButton extends SideNavbarItem {
	readonly type: SideNavbarItemType = SideNavbarItemType.Button;
	label: string;
	callback: (e: MouseEvent, item: SideNavbarButton) => void;

	availableOnMobile: boolean = true;

	permissions: {
		all?: PermissionType[];
		none?: PermissionType[];
	} = {
		all: [],
		none: [],
	};

	override asButton(): SideNavbarButton {
		return this;
	}

	constructor(init: Partial<SideNavbarButton>) {
		super();
		Object.assign(this, init);
	}
}

/* -------------------------------------------------- Separator -------------------------------------------------- */
export class SideNavbarSeparator extends SideNavbarItem {
	availableOnMobile: boolean;
	readonly type: SideNavbarItemType = SideNavbarItemType.Separator;
	label: string;
	permissions: {all?: PermissionType[]; none?: PermissionType[]} = {
		all: [],
		none: [],
	};

	constructor(init?: Partial<SideNavbarSeparator>) {
		super();
		Object.assign(this, init);
	}
}
