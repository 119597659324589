import {MenuItem} from 'primeng/api';
import {PermissionType} from '@core/api/data-access';
import {SidenavItemPermissionStruct} from './sidenav-item.types';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';

export type ISidenavItem = MenuItem & {
	permissions: SidenavItemPermissionStruct;
	items?: (SidenavItemModel | MenuItem)[] | undefined;
};

export class SidenavItemModel implements MenuItem {
	label: string;
	icon: string;
	styleClass: string;
	permissions: SidenavItemPermissionStruct;

	routerLink?: string;
	counter?: number = null
	items?: (SidenavItemModel | MenuItem)[];
	command?: (event: MenuItemCommandEvent) => void;

	isActive: (activeRoute: string) => boolean = (activeRoute: string) => activeRoute.includes(this.routerLink || '');


	constructor(label: string, icon: string, allowedPermissions: PermissionType[], deniedPermissions: PermissionType[], items?: (SidenavItemModel | MenuItem)[], route?: string, command?: (event: MenuItemCommandEvent) => void, isActive?: (activeRoute: string) => boolean, counter?: number,) {
		this.label = label;
		this.icon = icon ? icon + 'sub-item-icon fa-lg border border-solid aspect-square rounded-md border-gray-300 p-1 m-0' : '';
		this.styleClass = 'cursor-pointer font-dc';
		if (label === 'חדש' || label === 'New') this.styleClass = 'cursor-pointer font-dc bg-white text-gray-100 font-bold rounded-md';
		this.permissions = {
			allowed: allowedPermissions,
			denied: deniedPermissions,
		};
		this.routerLink = route ? route : '';
		this.counter = counter;
		this.items = items?.map(item => {
			item.parent = this;
			return item;
		});
		this.command = command;

		this.routerLink = route;
		isActive && (this.isActive = isActive);

		
	}

	/* ----------------------------------------------  Parent Node ---------------------------------------------- */
	private _parent: SidenavItemModel | null = null;
	public get parent(): SidenavItemModel | null {
		return this._parent;
	}

	public set parent(parent: SidenavItemModel | null) {
		if (parent) this.styleClass += 'sub-item';
		else this.styleClass = this.styleClass.replace('sub-item', '');
		this._parent = parent;
	}

	/* ------------------------------------------------  Icon ------------------------------------------------ */
}
